html[data-theme='light'] {
  --bg-color: #FFF;
  --primary-color: hsl(0, 0%, 93%);
  --secondary-color: hsl(0, 0%, 96%);
  --font-color: #000;
  --border-color: #000;
  --highlight-color: lightblue;

  --lootcrates-color: hsl(0, 0%, 65%);

  --boss-color: hsl(45, 100%, 45%);

  background: lightgray;
  color: var(--font-color);
}

:root {
  --bg-color: #000;
  --primary-color: hsl(0, 0%, 5%);
  --secondary-color: hsl(0, 0%, 10%);
  --font-color: #FFF;
  --border-color: #ddd;
  --highlight-color: darkblue;

  --lootcrates-color: hsl(0, 0%, 70%);

  --boss-color: hsl(45, 100%, 50%);
  background: #333;
  color: var(--font-color);
}

html.color-theme-in-transition,
html.color-theme-in-transition *,
html.color-theme-in-transition *:before,
html.color-theme-in-transition *:after {
  transition: all 700ms !important;
  transition-delay: 0 !important;
}

.feedEntry {
  list-style-type: none;
  margin: .15em 0;
  background: var(--secondary-color);
  padding: .3em .2em;
}

.feedCluster {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

html {
  height: 100%;
  padding: 0;
  margin: 0;
}

.card {
  width: 100vw - .3em;
  padding: .3em;
  height: 100vh;
  border-width: 0 1px;
  border-style: solid;
  border-color: var(--border-color);
  overflow-y: scroll;
  white-space: normal;
  display: inline-block;
  background: var(--bg-color);
}

@media (min-width: 30em) {
  .card {
    width: 23em;
    margin: 0 1px 0 1px;
    padding: .7em;
  }
}

.card button {
}

button {
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--font-color);
  font-family: "Open Sans","Helvetica Neue",sans-serif;
  font-weight: 700;
  background: var(--bg-color);
  color: var(--font-color);
  margin: .2em;
  padding: .2em .5em;
  vertical-align: middle;
}

button:hover {
  color: var(--bg-color);
  background: var(--font-color);
}

button.disabled {
  background: var(--bg-color);
  color: gray;
  cursor: not-allowed;
}

input[type=checkbox] {
  cursor: pointer;
}

.card h1 {
  margin-top: 0;
  font-family: "Lora";
  color: var(--font-color);
}

.cardContainer {
  height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.entry {
  display: inline-grid;
  grid-template-columns: 1.1em 1.6em auto;
  grid-column-gap: .3em;
  align-items: center;

  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  color: var(--font-color);
  width: 100%;

}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.entrySeason {
  font-weight: 200;
  font-size: .7em;
}

.entryDay {
  font-weight: 200;
  font-size: .7em;
}

.entryText {
  font-family: "Lora";
}

.plainlist {
  padding: 0;
}

.plainlist > li {
  list-style-type: none;
}

.feedList {
  padding: 0;
  background: var(--primary-color);
  margin: .4em 0;
  padding: .1em;
}

.typeCheckbox {
  font-size: small;
}

.typeModalColumns {
  columns: 3 20em;
}

.typeModal {
  background: var(--bg-color);
  position: absolute;
  inset: 40px;
  border: 1px solid var(--border-color);
  overflow: scroll;
  border-radius: 4px;
  padding: 20px;
}

.categorySelect {
  columns: 2;
}

.categoryOption input {
  margin-left: 0;
}

.selectedTypeCrumb {
  font-size: x-small;
  max-width: 10em;
  overflow: hidden;
  white-space: nowrap;
  margin: 2px;
  background: var(--highlight-color);
  font-style: oblique;
  border-radius: 5px;
}

.selectedTypes {
  display: flex;
  flex-wrap: wrap;
}

.titleEdit {
  font-size: x-large;
}

input {
  background-color: var(--bg-color);
  border: 1px solid var(--font-color);
  border-radius: 4px;
  color: var(--font-color);
}

.editButtons {
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.editButton {
  float: right;
}

.editPanel {
}

.editPanel > * {
  margin: 4px 0;
}

.editPanel > label {
  font-size: small;
}

.spinClark {
  width: 2em;
  height: 2em;
  /*background-image: url("./ballclark.png");*/
  animation: spinny 3s linear infinite;
  -webkit-animation: spinny 3s linear infinite;
  -moz-animation: spinny 3s linear infinite;
  -o-animation: spinny 3s linear infinite;
  -ms-animation: spinny 3s linear infinite;
}

.spinClark>img {
  width: 100%;
  height: 100%;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;

}

@keyframes spinny {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bigdeal { /* being -1 */
  font-weight: 700;
  font-style: italic;
  color: var(--font-color);
}

.shelledone { /* being 0 */
  color: red;
  font-weight: 700;
}

.monitor { /* being 1 */
  color: #5988FF;
  text-shadow: 0 0 20px;
  font-weight: 700;
}

.boss { /* being 2 */
  color: var(--boss-color);
  font-weight: 700;
}

.reader { /* being 3 */
  color: #A16DC3;
  text-shadow: 0 0 10px;
  font-weight: 700;
}

.lootcrates { /* being 5 */
  font-style: italic;
  color: var(--lootcrates-color);
}

.namerifeht { /* being 6 */
  color: #ea5b23;
  font-weight: 700;
}

.microphone {
  font-family: "Open sans", "Helvetica neue", sans-serif;
}

.loadMore {
  margin-bottom: 1em;
}

.defaultText {
  color: var(--font-color);
  font-family: "Open sans", helvetica, arial, "sans serif";
}

.refreshText {
  font-size: small;
  float: right;
  text-align: right;
}

.editDropdown__control {
  cursor: pointer;
  color: var(--font-color);
  background-color: var(--bg-color)!important;
}

.editDropdown__menu {
  color: var(--font-color);
  background-color: var(--bg-color)!important;
  border: 1px solid var(--border-color);
}

.editDropdown__option:hover, .editDropdown__option--is-focused {
  cursor: pointer;
  background-color: var(--highlight-color)!important;
}

.largeText {
  font-size: large;
}

.marginZero {
  margin: 0;
}

hr {
  border-width: 1px;
  border-style: solid;
  border-color: gray;
}

.faded {
  opacity: .2;
  animation: fadedOut .3s;
  -webkit-animation: fadedOut .3s;
  -moz-animation: fadedOut .3s;
  -o-animation: fadedOut .3s;
  -ms-animation: fadedOut .3s;
}

@keyframes fadedOut {
  0% {opacity:1;}
  100% {opacity:.2;}
}

.grid-2 {
  display: grid;
  grid-template-columns: auto 12em;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@keyframes metadataIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  50% {
    -webkit-transform: translateY(0%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

.metadataCard {
  padding-left: .7em;
  padding-top: .3em;
  align-items: bottom;
  animation: metadataIn .5s;
  -webkit-animation: metadataIn .5s;
  -moz-animation: metadataIn .5s;
  -o-animation: metadataIn .5s;
  -ms-animation: metadataIn .5s;
}

.metadataEmoji {
  font-size: x-small;
  margin-right: 1em;
}

.metadataChild {
  padding-top: .2em;
  padding-bottom: .2em;
  padding-left: 1em;
  font-size: small;
}

.metadataVotes {
  padding-top: .5em;
}

.feedMetadata {
  font-size: small;
  font-family: monospace, monospace;
}

.feedMetadataButton {
  font-size: x-small;
  cursor: pointer;
  float: right;
}

@keyframes feedMetadataIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10%);
  }

  50% {
    -webkit-transform: translateY(2%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

.feedMetadataList {
  padding-left: 2em;
  animation: feedMetadataIn .5s;
  -webkit-animation: feedMetadataIn .5s;
  -moz-animation: feedMetadataIn .5s;
  -o-animation: feedMetadataIn .5s;
  -ms-animation: feedMetadataIn .5s;
}

.feedMetadataList > li {
  list-style-type: none;
}

.jumpSetting > input {
  border-radius: 25px;
  max-width: 5em;
  margin-left: 1px;
  margin-right: 1px;
}
